.container {
	display: flex;
	margin-top: 6em;
	justify-content: center;
	flex-wrap: wrap;
}

.rocketDiv {
	margin-left: auto;
	margin-right: auto;
}

.rocket {
	width: 5rem;
	display: block;
	margin: auto;
}

.rocketVideo {
	transform: translateY(15%);
	scale: 1.8;
}

.description {
	min-width: 500px;
}
