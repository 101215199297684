.bannerLogo {
  height: 2.3rem;
}

.navbar {
  background: rgba(0, 0, 0, 0.85);
  z-index: 10;
  position: relative;
  height: 5rem;
  transition: background 0.3s;
}

.scrolled {
  background: rgba(0, 0, 0, 1);
}

.nav {
  width: 100%;
  justify-content: flex-end;
  @media (max-width: 1000px) {
    background: rgba(0, 0, 0, 0.95);
    text-align: center;
  }
}

.navbarContainer {
  margin: 0.25rem 0.5rem 0 0.5rem;
}

.navbarLink {
  font-weight: 300;
  font-size: 1.1rem;
  display: inline-block;
  padding-bottom: 0.8rem;
}
