.bannerPhoto {
    background-repeat: no-repeat;
    background-position: 100% 70%;
    background-size: cover;
    height: calc(100vh + 5rem);
    width: 100vw;
    top: 0;
    left: 0;
    margin-top: -8rem;
    position: relative
  }

.backgroundImage {
    filter: brightness(40%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
  }

.bannerText h1{
  position:absolute;
  top: 43%;
  left: 27%;
  transform: translate(-50%, -50%);
  color: #dbb548;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 85px;
  letter-spacing: 1px;
  text-align: center
}

.bannerText button{
  position: absolute;
  top: 62%; 
  left: 10%;
  transform: translateX(-50%); 
  color: white;
  display: flex;
  justify-content: center; 
  font-size: 20px;
  gap: 10px; 
  background-color: #dbb548;
  width: 175px;
  height: 50px;
  padding: 15px 30px;
  border-radius: 10px;
  border: none;
  align-items: center;
  transition: background-color 0.4s ease;
}

.bannerText button:hover,
.bannerText button:active{
  background-color: #b8963b;
  outline: none;
}

.bannerText a{
  position: absolute;
  top: 62%; 
  left: 30%;
  transform: translateX(-50%); 
  display: flex;
  justify-content: center; 
  font-size: 20px;
  gap: 10px; 
  background-color: #dbb548;
  width: 175px;
  height: 50px;
  padding: 15px 30px;
  border-radius: 10px;
  border: none;
  align-items: center;
  transition: background-color 0.4s ease;
}

.bannerText a:hover,
.bannerText a:active {
  background-color: #b8963b;
}
