.body{
    margin-bottom: 0.5rem;
}

.string{
    margin: 0%;
}

.group{
    margin-bottom: 0.5rem;
}