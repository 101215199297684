@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600');
/* General */

html, body, #root {
	min-height: 100vh;
}

#root{
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  padding-top: 4.175rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', Helvetica, sans-serif;
}

a:hover, a:visited {
  text-decoration: none;
}

a {
  color: #337ab7;
  text-decoration: none;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}