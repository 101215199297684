.imageLinkImage {
  display:block;
  width: 90%;
  margin: auto;
}

.imageLinkTitle {
  font-weight: 500;
  font-size: 1.5rem;
  margin: 1rem;
}

.imageLinkText {
  line-height: 135%;
  font-weight: 400;
  font-size: 0.9rem;
}

.imageLinkContainer {
  transition: transform 0.3s ease;
}

.imageLinkContainer:hover {
  transform: scale(1.005);
}

.imageLinkContainer a {
  color: black;
  text-align: center;
  transition: color 0.3s ease-in;
}

.imageLinkContainer:hover a {
  color: grey;
}



