.FAQBtn {
    border-radius: 0rem;
    border: 1px solid rgb(0, 0, 0, .3);
    background-color: rgb(0, 0, 0, .1);
    width: 100%;
    color: #1A4769;
    font-weight: bold;
    margin: 0;
    padding: 1%;
    display: block;
}

#FAQBtn {
  border-radius: 0rem;
  border: 1px solid rgb(0, 0, 0, .3);
  background-color: rgb(0, 0, 0, .1);
  width: 100%;
  color: #1A4769;
  font-weight: bold;
  margin: 0;
  padding: 1%;
  display: block;
}

.answer {
    border: 1px solid rgb(0, 0, 0, 0.3);
    width: 100%;
    margin: 0;
    padding: 1%;
}

.joinUl {
    list-style-type: initial;
}
