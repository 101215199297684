.footer {
  height: 5rem;
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background: #2b2b2b;
  background-color: rgba(0, 0, 0, 0.90);
  margin-top: auto;
}

.footer a img {
  height: 1.5rem;
}

.footerCol {
  display: inline-block;
}

.footerLink {
  margin: auto 2px;
}