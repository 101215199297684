.imgPlatinum {
  max-height: 9rem;
  max-width: 100%;
	margin-top: 1rem;
	margin-bottom: 1rem;
  transition: transform 0.3s ease;
}

.imgGold {
	width: auto;
	max-width: 35rem;
	display: block;
	margin: auto;
	margin-top: 1rem;
	margin-bottom: 1rem;
	height: 7rem;
  transition: transform 0.3s ease;
}

.imgSilver {
	width: auto;
	height: auto;
	max-width: 22rem;
	max-height: 6rem;
	vertical-align: middle;
  transition: transform 0.3s ease;
}

.imgBronze {
	height: 4.5rem;
	width: auto;
	vertical-align: middle;
	margin: 1rem;
}

.imgPrevious {
	height: 1.5rem;
	width: auto;
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
}

.logoContainerPlatinum {
	padding: 1rem;
	padding-top: 0rem;
	text-align: center;
	display: inline-block;
	background: white;
}
.logoContainer {
	text-align: center;
	display: inline-block;
	background: white;
}

.imageLinkText {
  line-height: 135%;
  font-weight: 400;
  font-size: 0.9rem;
}

.imgPlatinum:hover, .imgGold:hover, .imgSilver:hover {
  transform: scale(1.01);
}
