.container {
	display: flex;
	justify-content: center;
	padding-top: 10px;
	padding-bottom: 10px;
}

.upperPart {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.content {
	width: 100%;
}

.dividerLine {
	transform: translate(0px, 1em);
}

.morebutton {
	background-color: #8c0733;
	text-align: center;
	border-radius: 0.5rem;
	height: 2rem;
    max-width: 6rem;
    padding: 0.4rem;
    /* transitions */
    -webkit-transition: background-color 0.3s linear;
    -moz-transition: background-color 0.3s linear;
    -o-transition: background-color 0.3s linear;
    -ms-transition: background-color 0.3s linear;
    transition: background-color 0.3s linear;
}

.morebutton:hover  {
    background-color: #620423;
}

.morebutton h1 {
	color: #ffffff;
    font-family: 'Open Sans', Helvetica, sans-serif;
    font-weight: 600;
    font-size: 1rem;
}

.galleryContainer {
	padding: 10px;
	display: flex;
	flex-wrap: wrap;
}

.galleryTitle {
	font-weight: 500;
  	font-size: 1.5rem;
}

.photo {
	width: 100%;
	min-width: 270px;
	padding-top: 15px;
	padding-bottom: 15px;
}

.video {
	width: 100%;
	min-width: 270px;
	padding-top: 15px;
	padding-bottom: 15px;
}
