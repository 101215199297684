.aboutUsImageContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
.aboutUsImage {
    width: 10%;
    margin-bottom: 20px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .aboutUsImage img {
    width: 100%; 
    height: auto;
    object-fit: cover;
  }

  .homeContentTitle {
    font-weight: 800;
    font-size: 2rem;
    margin-top: 2.5rem;
    margin-bottom: 0.7rem;
    text-decoration: underline;
    text-decoration-color: #dbb548;
  }
  
  .homeContentTitleHr {
    margin: 0.5rem 0;
  }
  
  .homeContentText {
    text-align: left;
    line-height: 135%;
    font-weight: 400;
    font-size: 1.4rem;
  }
  