.title{
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
    font-family: 'Open Sans', Helvetica, sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 0.3rem;
}

.box {
    height: 17rem;
    padding: 0.5rem;
}

.box__inner {
    display: block;
    height: 15rem;
    min-width: 22rem;
    background-image: url('https://i.ytimg.com/vi/tntOCGkgt98/maxresdefault.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 6rem;
    transition: transform 0.3s ease;
}

.box__inner:hover {
    transform: scale(1.01, 1.01);
    box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
}







